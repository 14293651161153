@font-face {
    font-family: 'Poppins-Bold';
    src: url('../fonts/Poppins-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('../fonts/Poppins-SemiBold.otf') format('opentype');
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('../fonts/Poppins-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url('../fonts/Poppins-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'Poppins-Thin';
    src: url('../fonts/Poppins-Thin.otf') format('opentype');
}

@font-face {
    font-family: 'Poppins-Italic';
    src: url('../fonts/Poppins-Italic.otf') format('opentype');
}

body {
    font-family: 'Poppins-Regular', sans-serif;
}